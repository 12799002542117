import { DOCUMENT, isPlatformServer } from '@angular/common';
import { afterNextRender, computed, inject, Inject, Injectable, Injector, PLATFORM_ID, signal } from '@angular/core';
import { HOST_URL } from 'src/express.token';
import { Language } from 'src/types/language';

@Injectable({
  providedIn: 'root',
})
export class AppStore {
  private readonly state = {
    $appName: signal<string>(''),
    $language: signal<Language>({ code: 'en', name: 'English' }),
    $token: signal<string>(''),
    $hostUrl: signal<string>(''),
    $systemStrings: signal<SystemStrings>({}),
    $pageStrings: signal<PageStrings>({}),
    $siteHome: signal<string>('/'),
    $webManifest: signal<WebManifest | null>(null),

    $showLogonButton: signal<boolean>(true),
  };

  public readonly $appName = this.state.$appName.asReadonly();
  public readonly $language = this.state.$language.asReadonly();
  public readonly $showLogonButton = this.state.$showLogonButton.asReadonly();
  public readonly $token = this.state.$token.asReadonly();
  public readonly $isLoggedIn = computed(() => (this.$token() ? true : false));
  public readonly $hostUrl = this.state.$hostUrl.asReadonly();
  public readonly $systemStrings = this.state.$systemStrings.asReadonly();
  public readonly $pageStrings = this.state.$pageStrings.asReadonly();
  public readonly $siteHome = this.state.$siteHome.asReadonly();
  public readonly $webManifest = this.state.$webManifest.asReadonly();

  constructor(
    private injector: Injector,
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    afterNextRender(async () => {
      this.token = localStorage.getItem('x-dynatex-token') ?? '';
      this.state.$hostUrl.set(document.location.hostname);
      const webManifest = this.getWebManifest();
      const siteHome = this.detectIfPwa() ? (await webManifest)?.start_url ?? this.getWebHome() : this.getWebHome();
      this.state.$siteHome.set(siteHome);
      this.state.$webManifest.set(await webManifest);
    });
    if (isPlatformServer(this.platformId)) {
      this.state.$hostUrl.set(inject(HOST_URL, { optional: true }) ?? '');
    }
  }

  set appName(value: string) {
    this.state.$appName.set(value);
  }

  set language(value: Language) {
    this.state.$language.set(value);
  }

  set showLogonButton(value: boolean) {
    this.state.$showLogonButton.set(value);
  }

  set token(value: string) {
    this.state.$token.set(value);
    localStorage.setItem('x-dynatex-token', value);
  }

  set systemStrings(value: SystemStrings) {
    this.state.$systemStrings.update(prev => ({ ...prev, ...value }));
  }
  set pageStrings(value: PageStrings) {
    this.state.$pageStrings.update(prev => ({ ...prev, ...value }));
  }

  detectIfPwa(): boolean {
    if (typeof window === 'undefined') return false;
    return window.matchMedia('(display-mode: standalone)').matches ?? false;
  }

  async getWebManifest(): Promise<WebManifest | null> {
    try {
      return await fetch('/manifest.webmanifest')
        .then(res => res.json())
        .then(webmanifest => webmanifest);
    } catch (error) {
      console.error('Error fetching manifest', error);
      return null;
    }
  }

  getWebHome(): string {
    return '/';
  }
}
