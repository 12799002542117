import { computed, Injectable, Signal } from '@angular/core';
import { AppStore } from 'src/app/store/app.store';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private readonly $systemStrings: Signal<SystemStrings>;
  private readonly $pageStrings: Signal<PageStrings>;

  constructor(private appStore: AppStore) {
    this.$systemStrings = appStore.$systemStrings;
    this.$pageStrings = appStore.$pageStrings;
  }

  public translateSignal(key: string | null, defaultValue?: string) {
    return computed(() => this.translate(key, defaultValue));
  }

  public translate(key: string | null, defaultValue?: string): string {
    if (!key) return defaultValue || '';

    if (this.$systemStrings()[key] !== undefined) {
      return this.$systemStrings()[key];
    }
    if (this.$pageStrings()[key] !== undefined) {
      return this.$pageStrings()[key];
    }
    return defaultValue || key;
  }
}
